/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                $('.sliderSplash').owlCarousel({
                    nav: false,
                    items: 1,
                    mouseDrag: false,
                    touchDrag: false,
                    pullDrag: false,

                });

                $('.sliderCont').owlCarousel({
                    nav: true,
                    items: 1,
                    loop: true,
                    navText: ["<i class='fa fa-long-arrow-left'></i>", "<i class='fa fa-long-arrow-right'></i>"]
                });

                $('.sliderBottoni li:first-child').addClass('active');
                $('.sliderBottoni li').each(function() {
                    $(this).on('click', function() {
                        $('.sliderBottoni li').removeClass('active');
                        $(this).addClass('active');
                        var etq = $(this).attr('data-drag');
                        $('.sliderSplash').trigger('to.owl.carousel', etq - 1)
                    });
                });

                $('body.page-template-template-splash2 img').imagesLoaded(function() {
                    setTimeout(function() {
                        $('body.page-template-template-splash2 .sez.colore').addClass('attiva');
                        $('body.page-template-template-splash2 .b-w.sez').delay(1000).fadeOut(300);
                        $('body.page-template-template-splash2 .logoClick').addClass('attiva');
                        $('body.page-template-template-splash2 .animelle').addClass('attiva');
                        $('body.page-template-template-splash2 .goOn').addClass('attiva');
                    }, 3000);
                });



                $('.icon .iconCont').on('click', function() {
                    $('header.banner').toggleClass('aperto');
                    $('.icon .iconCont').toggleClass('active');
                });

                $('.sClick').on('click', function() {
                    $(this).toggleClass('attiva');
                });


                // VIDEO
                $('.lanciovideo').on('click', function() {
                    var IDYT = $(this).find('a').attr('id');
                    $('.videoCont#' + IDYT).fadeIn();
                });

                $('.videoCont .chiudiVideo').on('click', function() {
                    var $video = $(this).parent().find('#video');
                    $(this).parent().find('#video').remove();
                    $(this).parent().fadeOut();
                    $(this).parent().append($video);
                });

                $('.grid').imagesLoaded(function() {
                    $('.grid').masonry({
                        itemSelector: '.grid-item',
                        percentPosition: true
                    });
                });

                $("#share").jsSocials({
                    showLabel: false,
                    shares: ["twitter", "facebook", "linkedin"]
                });

                $("#lightgallery").lightGallery();

                // GESTIONE MAX WIDTH NELLE SPALSH
                $('body.page-template-template-splash2 main.main,body.page-template-template-splash main.main').css({ 'max-width': '100%' });


                $('body.home .singAnima').each(function() {
                    $(this).on('click', function() {
                        var id = $(this).attr('id');
                        $('body.home .singAnima').removeClass('active')
                        $(this).addClass('active');
                        $('.testiAnime .arr').removeClass('active');
                        $('.testiAnime').find('#' + id).addClass('active');
                    });
                });




                function ContenitoreUgualeImg() {
                    $('body.page-template-template-splash2 .b-w img').imagesLoaded(function() {
                        var Himg = $('body.page-template-template-splash2 .b-w img').height();
                        $('.anime').css({ 'height': Himg });
                    });
                }

                ContenitoreUgualeImg();

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.